<template>
  <ion-page class="page scrollable bg-transparent pb-5">
    <Breadcrumbs :breadcrumbs="ocsBreadcrumbs" />
    <PageHeader class="mt-4" :title="sectionTitle" :subtitle="subtitle" invert />
    <section class="mt-2">
      <p v-if="pageData?.blurb">
        {{ pageData.blurb }}
      </p>
    </section>
    <ParagraphWithTitle v-if="pageData?.html_description" class="mt-4" :title="''" :body="pageData?.html_description" />
    <section class="buttons mt-4 px-2">
      <ButtonGrid
        :style="{ '--background': '#999', textTransform: 'unset', '--border-radius': '20px' }"
        :buttons="links"
      />
    </section>

    <section class="buttons mt-5" v-if="pageData.characters?.count">
      <h2 class="title mb-3">{{ pageData.name }}&nbsp;OCs ({{ pageData.characters?.count }} characters)</h2>
      <InfiniteCharacters
        class="mt-2"
        :characters="characters"
        :loading="false"
        :paging="charsPaging"
        :hideTypeTag="true"
        :showCharacterTags="true"
        :pagination="true"
        :listLayout="true"
        :readOnly="true"
        @load="loadNextPage"
      />
    </section>
  </ion-page>
</template>

<script lang="ts" setup>
import { Character, Paging } from '@/shared/types/static-types';
import Breadcrumbs from '@/shared/components/breadcrumbs.vue';
import ButtonGrid from '@/shared/components/button-grid.vue';
import PageHeader from '@/shared/components/page-header.vue';
import ParagraphWithTitle from '@/shared/components/paragraph-with-title.vue';
import InfiniteCharacters from '@/shared/components/InfiniteCharacters.vue';
import image from '../../../public/images/header_OCs.png';
import { getOriginalCharacterCategoryPage } from '@/shared/actions/public';
import { getNextPage } from '@/shared/helpers/pagination';

const {
  public: { baseCDNUrl },
} = useRuntimeConfig();
const picture = ref(`${baseCDNUrl}${image}`);
const description = ref('');
const pageTitle = ref('Original Character (OCs) - CharacterHub');
const title = ref('Original Character (OCs) | CharacterHub');
const allowCrawlers = ref(true);
const allowAiBots = ref(false);
const route = useRoute();
const url = ref(`https://characterhub.com${route.path}`);

const ocsBreadcrumbs = ref([
  {
    label: 'Home',
    href: '/',
  },
  {
    label: 'Original Characters',
    href: '/original-character',
  },
]);
const pageData = useState<any>('pageData', () => ({}));
const sectionTitle = ref('Original Characters (OCs):');
const subtitle = ref('Explore Character Designs');
const characters = ref<Character[]>([]);
const charsPaging = ref<Paging>();

const links = ref<any[]>([
  { label: 'Original Characters', href: '/original-character' },
  { label: 'OC Art', href: '/original-character-art' },
]);

const loadPageData = async (slug: string) => {
  pageData.value = await getOriginalCharacterCategoryPage(slug);
  if (!pageData.value) return;
  const { results, ...paging } = pageData.value.characters;
  charsPaging.value = paging as Paging;
  characters.value = [...results];
};

const loadNextPage = async () => {
  if (!charsPaging.value?.next) return;
  const { results, ...paging } = await getNextPage(charsPaging.value);
  charsPaging.value = paging;
  characters.value = [...characters.value, ...results];
};

function formatLinks(links: any[]) {
  return (
    links?.map((link) => {
      if (link.length === 3 && link[2] === 'oc') {
        return {
          href: '/original-character/' + link[1],
          label: link[0] + ' OCs',
        };
      }
      return {};
    }) || []
  );
}

await useChAsyncData(async () => {
  const { slug } = route.params;
  await loadPageData(slug as string);
  if (characters.value?.length) {
    picture.value = `${characters.value![0]?.image}`;
  }
  sectionTitle.value = pageData.value.name + ' ' + sectionTitle.value;
  description.value = pageData.value.blurb || `Explore ${pageData.value?.characters?.count || ''} Sonic OCs created by a community on CharacterHub! Check out their character designs, art, lore, and more!`
  pageTitle.value = `${pageData.value.name} Original Characters (OCs) | CharacterHub`;
  title.value = `${pageData.value.name} OCs | CharacterHub`;
  ocsBreadcrumbs.value = [
    ...ocsBreadcrumbs.value,
    {
      label: pageData.value.name,
      href: `/original-character/${slug}`,
      isActive: true,
    },
  ];
  links.value = [...links.value, ...formatLinks(pageData.value.related_pages), ...formatLinks(pageData.value.pages)];
  useChHead(pageTitle, title, description, url, picture, allowCrawlers, allowAiBots);
  return true;
});

onMounted(() => {
  const document = useDocument();

  if (!document.value) return;

  document.value.getElementById('app-nav-bar')?.scrollIntoView({ behavior: 'smooth' });
});
</script>

<style lang="sass" scoped>
.borders
  border-radius: 12px
.spacer
  border-bottom: 3px solid #AE38E5
h1
  margin: 2rem auto
.page
  ::v-deep
    h3
      font-size: 16px !important
      font-weight: bold !important
    p
      margin: 10px 0
article
  background: rgba(121, 121, 121, 0.2)
  padding: 1rem
  border-radius: 10px
.title
  font-size: 1.5rem
  font-weight: bold
  margin-bottom: 3rem
.fandom
  border-radius: 16px
  background: rgba(121, 121, 121, 0.2)
  padding: 0.4rem 1rem
  font-size: 0.8rem
  min-width: 100px
  text-align: center
.fanart
  object-fit: cover
  width: 100%
  height: 100%
.fan-art-card
  border-radius: 10px
  height: 300px
.art-link
  font-weight: bold
.art-caption
  text-align: center
</style>
